import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import times from 'lodash/times';
import uniqBy from 'lodash/uniqBy';
import React, {useEffect, useState} from 'react';
import WhiteText from '../../components/WhiteText';
import filterBoolean from '../../functions/filterBoolean';
import useStatus from '../../hooks/useStatus';
import Funnel from '../../models/fountain/Funnel';
import LocationGroup from '../../models/fountain/LocationGroup';
import ScoopMApi from '../../references/scoopm-api';

// Add type declaration for the custom element
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'zapier-interfaces-chatbot-embed': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          'is-popup'?: string;
          'chatbot-id'?: string;
        },
        HTMLElement
      >;
    }
  }
}

type GroupWithFunnels = LocationGroup & {funnels?: Funnel[]};

export default function Openings() {
  // const {handlePromise, status, error} = useStatus();
  // const [groups, setGroups] = useState<GroupWithFunnels[]>();

  // Add useEffect for chatbot script
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js';
    script.type = 'module';
    script.async = true;
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="container py-2 py-md-3">
      <h1 className="display-2 mb-0">Drive for Wave</h1>
      <br />

      <h2>Rideshare and Delivery Driver Applicant Portal</h2>
      <a target="_blank" style={{color: '#00B6D9'}} href="https://general-driver-application.zapier.app/">Click here to apply!</a>
      <br/>
      <br/>

      {/* Add chat section with more visibility */}
      <div className="mt-4 p-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px' }}>
        <h3>Have Questions? Chat With Us! 💬</h3>
        <p className="mb-3">Our AI assistant is here to help answer your questions about driving with Wave. Look for the chat icon in the bottom right corner!</p>
        <zapier-interfaces-chatbot-embed 
          is-popup='true' 
          chatbot-id='cm2ahngqr0019we3v3l5xc3w8'
        />
      </div>

      {/*  {error ? (
        <div className="alert alert-warning">Unable to list openings at this time</div>
      ) : groups ? (
        groups.map(group => (
          <div key={group.id} className="mb-3">
            <h1 className="display-4 text-primary mb-2">{group.name}</h1>
            {group.funnels?.map(funnel => (
              <div key={funnel.id} className="mb-1">
                <a href={funnel.apply_url} target="_blank" rel="noopener noreferrer">
                  {funnel.title}
                </a>
              </div>
            )) ?? <span className="text-muted">No openings at this time</span>}
          </div>
        ))
      ) : (
        times(2, i => (
          <div key={i} style={{width: '350px'}} className="mb-3">
            <div className="loading-animation">
              <h1 className="display-4 mb-2">
                <WhiteText />
              </h1>
            </div>
            {times(12, j => (
              <div key={j} className="loading-animation mb-1">
                <WhiteText />
              </div>
            ))}
          </div>
        ))
      )}

      {groups && (
        <>
          <hr />
          <div className="mt-4 mb-5">
            <small className="text-muted">
              You'll fill out your application on the secure site of our our partner,{' '}
              <a href="https://get.fountain.com/" target="_blank" rel="noopener noreferrer">
                Fountain
              </a>
            </small>
          </div>
        </>
      )}
      */}
    </div>
  );
}

// async function getFunnels(page = 0): Promise<Funnel[]> {
//   const response = await ScoopMApi.instance.fountain.funnels.list(page);
//   if (response.data.pagination.current < response.data.pagination.last) {
//     return [...response.data.funnels, ...(await getFunnels(page + 1))];
//   }
//   return response.data.funnels;
// }

