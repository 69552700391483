import React from 'react';
import {Link} from 'react-router-dom';
import Anchor from '../../components/Anchor';
import DownloadOnAppStore from '../../components/app-download-buttons/DownloadOnAppStore';
import GetItOnGooglePlay from '../../components/app-download-buttons/GetItOnGooglePlay';
import BackgroundImageCarousel from '../../components/BackgroundImageCarousel';
import FontAwesome from '../../components/FontAwesome';
import logoWhite from '../../images/logo_words_trans_white.png';
import RideNow from './RideNow';

export default function Intro() {
  return (
    <div
      className="mt-2 mt-md-4 position-relative bg-dark rounded-xl mx-2 mx-md-3 text-white"
      style={{overflow: 'hidden'}}>
      <Anchor id="top" />

      <BackgroundImageCarousel />

      <div className="position-relative container p-0 mx-auto">
        <div className="row">
          <div className="col col-md-auto">
            <div className="bg-dark-trans bg-blur pt-4 pb-5 px-3 p-lg-4 px-lg-5">
              {/* text/logo */}

              <div className="mb-5 mt-5">
                <div>
                  <img src={logoWhite} alt="Logo" style={{width: '221px', maxWidth: '200px'}} />
                </div>
                <h2 className="font-weight-light">safer rideshare</h2>
                <h2 className="font-weight-light">high-quality food delivery</h2>
              </div>

              {/* search field */}

              <RideNow />

              {/* buttons */}

              <div className="row no-gutters mb-3 align-items-center">
                <div className="col-auto">
                  <GetItOnGooglePlay style={{margin: '0 0 0 -1rem'}} />
                </div>
                <div className="col-auto pl-lg-1">
                  <DownloadOnAppStore />
                </div>
                <div className="col-auto pl-3">
                  <Link to="/openings" className="btn border-white text-white">
                    <FontAwesome.CarSolid /> Driver sign-up
                  </Link>
                </div>
                <div className="col-auto pl-3">
                  <a href="tel:980-337-4151" className="btn border-white text-white">
                    <i className="fas fa-phone" /> Book via Concierge
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
